$default_bopis_font_size: 14px;

@import '../theme-bootstrap';

.location {
  &__zip-container {
    &.invalid-zip {
      .location__zip-title-code {
        color: $red;
        text-transform: capitalize;
        letter-spacing: -1px;
      }
    }
  }
  &__icon {
    position: absolute;
    #{$ldirection}: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
  }
  &__zip-title {
    &-heading {
      font-size: 18px;
      line-height: 28px;
    }
    &-notice {
      font-size: #{$default_bopis_font_size};
      line-height: 18px;
      margin: 0;
    }
    &-code-value {
      display: flex;
      align-items: center;
      margin-bottom: base-line-calc(0.5, $h3-pc-size);
      p {
        font-size: #{$default_bopis_font_size};
        line-height: 28px;
        margin: 0;
      }
    }
    &-code-text {
      position: relative;
      padding-#{$ldirection}: 22px;
      white-space: nowrap;
    }
    &-edit {
      font-size: #{$default_bopis_font_size};
      text-decoration: underline;
      line-height: 18px;
      margin: 0;
      cursor: pointer;
      padding-#{$ldirection}: 10px;
    }
    &-code {
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
  &__zip-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include breakpoint($bp--small-up) {
      flex-wrap: nowrap;
    }
    &-block {
      position: relative;
      flex: 1;
      margin-#{$rdirection}: 18px;
    }
    &-input {
      width: 100%;
      padding-left: 32px;
      border: 1px solid $color--gray--lighter;
      height: 40px;
      text-transform: uppercase;
      &::placeholder {
        font-size: 14px;
        text-transform: initial;
      }
      .selection-on-pdp & {
        border-radius: 20px;
      }
    }
  }
  &__search_btn {
    top: 0;
    min-width: 99px;
    height: 40px !important;
    line-height: 42px !important;
    .selection-on-pdp & {
      border-radius: 20px;
    }
  }
  &__search-cancel-btn {
    font-size: #{$default_bopis_font_size};
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
    padding-#{$ldirection}: 10px;
  }
  &__input-icon {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &__search-current-location {
    cursor: pointer;
    left: unset;
    background: $white;
    #{$rdirection}: 10px;
    top: 4px;
    height: 30px;
    padding: 5px;
  }
  &__zip_error_text {
    position: relative;
    padding-#{$ldirection}: 22px;
  }
}
